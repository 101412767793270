<template>
  <ClientOnly>
    <Teleport to="body">
      <div
        class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 opacity-50 cursor-wait"
      >
        <AtomsLoadingSpinner />
      </div>
    </Teleport>
  </ClientOnly>
</template>

<style></style>

<script setup></script>
